import React from 'react';
import { ReactComponent as Logo } from '../images/ui/logo.svg';

export default function Header({classesWrapper, classesInner, classesLogo, classesHeading, classesLink, onClick}) {
  return (
    <div className={classesWrapper}>
      <div className={classesInner}><div className={classesLogo}><button onClick={onClick}><Logo className={classesLogo} /></button></div><h1 className={classesHeading}><a className={classesLink} href='https://poolsidebycgt.com/'>Poolside Visualizer by CGT</a></h1></div>
    </div>
  );
}
