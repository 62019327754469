import React from 'react';
import MediaQuery from 'react-responsive'
import { ReactComponent as Freeform } from '../images/shape/freeform.svg';
import { ReactComponent as Rectangle } from '../images/shape/rectangle.svg';
import shapeDesktop from '../images/ui/shape.jpg';
import shapeMobile from '../images/ui/shape-mobile.jpg';
import Button from 'react-bootstrap/Button';

function Shapes(props) {
  return (
    <>
      <MediaQuery minWidth={576}>
        <img src={shapeDesktop} alt='Shapes' className='img-fluid w-100' />
      </MediaQuery>
      <MediaQuery maxWidth={575.98}>
        <img src={shapeMobile} alt='Shapes' className='img-fluid w-100' />
      </MediaQuery>
      <div className='row g-0 shapes radio-list position-absolute h-100 w-100 top-50 start-50 translate-middle flex-column flex-sm-row justify-content-around'>
        <div className='col d-flex justify-content-center align-items-center'>
          <Button variant="light" className='text-start' onClick={() => props.onClick('3', 'Freeform')} >
            <Freeform className='me-3' /> FREEFORM POOL
          </Button>
        </div>
        <div className='col d-flex justify-content-center align-items-center'>
          <Button variant="light" className='text-start' onClick={() => props.onClick('4', 'Rectangle')} >
            <Rectangle className='me-3' /> RECTANGLE POOL
          </Button>
        </div>
      </div>
    </>
  )

}

export default Shapes