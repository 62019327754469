import React from 'react';
import { useEffect, useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Form from 'react-bootstrap/Form'

export default function Carousel({ url, setCarouselLoad, carouselLoad, visibility, dayNight, setDayNight, dataUrl }) {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);

  // Note: the empty deps array [] means
  // this useEffect will run once
  // similar to componentDidMount()
  // passing url runs everytime url changes
  useEffect(() => {
    fetch(url)
      .then(res => res.json())
      .then(
        (result) => {
          setIsLoaded(true);
          setItems(result);
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      )
  }, [url])
  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    return <div>Loading...</div>;
  } else {
    return (
      <div id="carouselVisualizer" className="carousel slide ms-lg-4" data-bs-interval="false" style={{ visibility: visibility }}>
        <div className="carousel-indicators">
          <Accordion>
            <Accordion.Item eventKey="0">
              <Accordion.Header>More Views </Accordion.Header>
              <Accordion.Body>
                {items.map((item, index) => (
                  <a key={index} href={index} type="button" data-bs-target="#carouselVisualizer" data-bs-slide-to={index} className={index === 0 ? 'active' : ''} aria-current="true" aria-label={`Slide ${index}`}>
                    <img src={dataUrl + item.img} className="d-block w-100" alt="..." />
                  </a>
                ))}
                <div className='control-switch d-flex align-items-center'>
                  <label title="Day" htmlFor='day-night-switch' className='form-check-label pe-1'>Day</label>
                  <Form.Check
                    inline
                    type="switch"
                    id="day-night-switch"
                    checked={dayNight}
                    onChange={() => setDayNight()}
                  />
                  <label title="Night" htmlFor='day-night-switch' className='form-check-label ps-1'>Night</label>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
        <div className="carousel-inner">
          {(carouselLoad) && (
            <div className='wrapper w-100 h-100 d-flex position-absolute justify-content-center align-items-center'>
              <div className='spinner-border text-secondary zindex-dropdown' role="status">
                <span className='visually-hidden'>Loading...</span>
              </div>
            </div>
          )}
          {items.map((item, index) => (
            <div key={index} className={`carousel-item ${index === 0 ? 'active' : ''}`}>
              <img src={dataUrl + item.img} className="d-block w-100" alt="..." onLoad={(x) => setCarouselLoad(index)} />
            </div>
          ))}
        </div>
      </div>
    );
  }
}
