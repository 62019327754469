import React, { useState } from 'react';
import { PDFDocument, StandardFonts, rgb } from 'pdf-lib';
import { ReactComponent as SaveIcon } from '../images/ui/save.svg';
import Button from 'react-bootstrap/Button';
import { useAsyncFn } from 'react-use';

const PdfEu = ({ shape, liner_info, deck, siteUrl }) => {
  const [loadStatus, setLoadStatus] = useState(false);
  const [state, doFetch] = useAsyncFn(async () => {
    const existingPdfBytes = await fetch(
      siteUrl + '/pdf/eu.pdf'
    ).then((res) => res.arrayBuffer());
    const linerImgUrl = siteUrl + '/pdf/images/liners/' + liner_info.liner_id + '/liner.jpg';
    const deckImgUrl = siteUrl + '/pdf/images/deck/' + deck.deck_id + '.jpg';
    const mainImgUrl = siteUrl + '/pdf/images/liners/' + liner_info.liner_id + '/' + shape + '_' + deck.deck_id + '.jpg';

    const linerImgBytes = await fetch(linerImgUrl).then((res) => res.arrayBuffer());
    const deckImgBytes = await fetch(deckImgUrl).then((res) => res.arrayBuffer());
    const mainImgBytes = await fetch(mainImgUrl).then((res) => res.arrayBuffer());

    const pdfDoc = await PDFDocument.load(existingPdfBytes);
    const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);

    const pages = pdfDoc.getPages();
    const firstPage = pages[0];

    const linerImg = await pdfDoc.embedJpg(linerImgBytes);
    const deckImg = await pdfDoc.embedJpg(deckImgBytes);
    const mainImg = await pdfDoc.embedJpg(mainImgBytes);

    const mainDims = mainImg.scale(0.205);

    const svgPath1 = 'm-54.398 19.355v97.367h182.03v-97.367h-182.03zm4.9366 1.0413h172.16c2.1987 0 3.9688 1.7701 3.9688 3.9688v87.347c0 2.1987-1.7701 3.9688-3.9688 3.9688h-172.16c-2.1987 0-3.9688-1.7701-3.9688-3.9688v-87.347c0-2.1987 1.7701-3.9688 3.9688-3.9688z';

    const svgPath3 = 'm36.943 83.569v30.339h30.339v-30.339zm6.7267 0.29146h16.885c3.554 0 6.4353 2.8803 6.4353 6.4342v16.886c0 3.554-2.8813 6.4353-6.4353 6.4353h-16.885c-3.554 0-6.4353-2.8813-6.4353-6.4353v-16.886c0-3.554 2.8813-6.4342 6.4353-6.4342z';

    const svgPath2 = 'm0 0h487.66c6.931 0 12.55-5.619 12.55-12.55v-247.24c0-6.932-5.619-12.55-12.55-12.55h-487.66c-6.931 0-12.55 5.618-12.55 12.55v247.24c0 6.931 5.619 12.55 12.55 12.55m0-5.379c-3.954 0-7.172-3.217-7.172-7.171v-247.24c0-3.955 3.218-7.172 7.172-7.172h487.66c3.954 0 7.171 3.217 7.171 7.172v247.24c0 3.954-3.217 7.171-7.171 7.171h-487.66';

    firstPage.drawText(liner_info.liner_name, {
      x: 182,
      y: 360,
      size: 12,
      font: helveticaFont,
      color: rgb(0.03, 0.14, 0.29),
    });

    firstPage.drawText(deck.deck_name, {
      x: 423,
      y: 360,
      size: 12,
      font: helveticaFont,
      color: rgb(0.03, 0.14, 0.29),
    });

    firstPage.drawText(liner_info.liner_base, {
      x: 230.5,
      y: 339.5,
      size: 9,
      font: helveticaFont,
      color: rgb(0.03, 0.14, 0.29),
    });

    firstPage.drawText(liner_info.liner_desc.replace(/<[^>]+>/g, ''), {
      x: 85,
      y: 280,
      size: 10,
      lineHeight: 13,
      font: helveticaFont,
      color: rgb(0.03, 0.14, 0.29),
      maxWidth: 450
    });

    firstPage.drawImage(mainImg, {
      x: firstPage.getWidth() / 2 - mainDims.width / 2 - 18,
      y: firstPage.getHeight() - mainDims.height - 85,
      width: mainDims.width + 32,
      height: mainDims.height,
    })

    firstPage.drawSvgPath(svgPath1, {
      x: firstPage.getWidth() / 2 - mainDims.width / 4 + 12,
      y: firstPage.getHeight() - 28,
      color: rgb(1, 1, 1),
      scale: 2.84,
    })

    firstPage.drawSvgPath(svgPath2, {
      x: firstPage.getWidth() / 2 - mainDims.width / 2 - 10.5,
      y: firstPage.getHeight() - mainDims.height - 90,
      color: rgb(0.02, 0.18, 0.37),
    })

    firstPage.drawImage(linerImg, {
      x: 82,
      y: 334,
      width: 85,
      height: 85,
    });

    firstPage.drawImage(deckImg, {
      x: 323,
      y: 334,
      width: 85,
      height: 85,
    });

    firstPage.drawSvgPath(svgPath3, {
      x: -23,
      y: 656,
      color: rgb(1, 1, 1),
      scale: 2.83,
    })

    firstPage.drawSvgPath(svgPath3, {
      x: 218,
      y: 656,
      color: rgb(1, 1, 1),
      scale: 2.83,
    })

    if (liner_info.liner_cat1.startsWith('aqua')) {
      const linerCat1ImgUrl = siteUrl + '/pdf/images/aquafeature/' + liner_info.liner_cat1 + '.png';
      const linerCat1ImgBytes = await fetch(linerCat1ImgUrl).then((res) => res.arrayBuffer());
      const linerCat1Img = await pdfDoc.embedPng(linerCat1ImgBytes);

      firstPage.drawImage(linerCat1Img, {
        x: 145,
        y: 400,
        width: 30,
        height: 30,
      });
    }

    const pdfBytes = await pdfDoc.save();
    const docUrl = URL.createObjectURL(
      new Blob([pdfBytes], { type: 'application/pdf' })
    );

    setLoadStatus(true);

    return docUrl
  }, [liner_info.liner_id, deck.deck_id, shape]);

  return (
    <div>
      {state.loading
        ? <div><Button variant="light" className='text-start ms-4 mt-4'><SaveIcon className='mb-1' /> <span className='ms-1'>Generating...</span></Button></div>
        : state.error
          ? <div>Error: {state.error.message}</div>
          : loadStatus ? <div><Button variant="light" className='text-start ms-4 mt-4' href={state.value} download={`${liner_info.liner_name}.pdf`}><SaveIcon className='mb-1' /> <span className='ms-1'>Download PDF</span></Button></div>
            : <div><Button variant="light" className='text-start ms-4 mt-4' onClick={() => doFetch()}><SaveIcon className='mb-1' /> <span className='ms-1'>Save As PDF</span></Button></div>
      }
    </div>
  );
};

export default PdfEu;