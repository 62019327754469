import React from 'react';
import DeckImage from './DeckImage';
import DecksData from './DecksData';

export default function Decks(props) {
  const decks = DecksData.map((item) => {
    return (
      <div key={item.id} className='col mx-3'>
        <label>
          <input
            type="radio"
            value={item.id}
            checked={props.deck === item.id}
            onChange={() => props.onClick(item.id, item.name)}
            name="decks"
            disabled={props.disabled}
          />
          <DeckImage name={item.name} image={item.img} />
          {item.name}
        </label>
      </div>
    )
  })

  return <div className='row decks radio-list'>{decks}</div>

}
