const DecksData = [
  {
    id: "5",
    name: "Concrete",
    img: require("../images/deck/concrete.jpg"),
  },
  {
    id: "6",
    name: "Flagstone",
    img: require("../images/deck/flagstone.jpg"),
  },
  {
    id: "7",
    name: "Paver",
    img: require("../images/deck/paver.jpg"),
  },
  {
    id: "8",
    name: "Travertine",
    img: require("../images/deck/travertine.jpg"),
  },
  {
    id: "9",
    name: "Wood",
    img: require("../images/deck/wood.jpg"),
  },
];

export default DecksData;
