import React from 'react';
import { useState, useEffect, useRef } from 'react';
import { ReactComponent as LinerIcon } from '../images/ui/liner-icon.svg';
import { ReactComponent as DeckIcon } from '../images/ui/deck-icon.svg';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

export default function ControlsBar({ linerName, deckName, shape, setShowLiners, setShowDecks, setShape }) {
  const [height, setHeight] = useState(0)
  const ref = useRef(null)
  const styles = {
    marginBottom: 105 - height
  };

  useEffect(() => {
    setHeight(ref.current.clientHeight)
  })

  return (
    <>
      <div className='controls-bar mx-4 px-3 pb-0 d-flex justify-content-evenly align-items-start' ref={ref} style={styles}>
        <div className='control-switch pt-3 d-flex align-items-center'>
          <label title="Freeform" htmlFor='shape-switch' className='form-check-label pe-1'>Freeform</label>
          <Form.Check
            inline
            type="switch"
            id="shape-switch"
            checked={shape === '4'}
            disabled={!deckName}
            onChange={() => setShape()}
          />
          <label title="Rectangle" htmlFor='shape-switch' className='form-check-label ps-1'>Rectangle</label>
        </div>
        <div className='dropdown-buttons d-flex'>
          <Button variant="light" className='control text-start d-flex flex-column pt-3 px-4 mx-2' disabled={!deckName} onClick={() => setShowLiners()}>
            <div className='control-content'><LinerIcon /> POOL LINER {linerName ? <><br /><span className='pool-deck ps-4 ms-3'>{linerName}</span></> : ''}</div>
          </Button>
          <Button variant="light" className='control text-start d-flex flex-column pt-3 px-4 mx-2' disabled={!deckName} onClick={() => setShowDecks()}>
            <div className='control-content'><DeckIcon /> POOL DECK {deckName ? <><br /><span className='pool-deck ps-4 ms-1'>{deckName}</span></> : ''}</div>
          </Button>
        </div>
      </div>
    </>
  );
}
