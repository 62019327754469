import React from 'react';
import { ReactComponent as LinerIcon } from '../images/ui/liner-icon-w.svg';
import { ReactComponent as DeckIcon } from '../images/ui/deck-icon-w.svg';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

export default function ControlsBarMobileLandscape({ liner, deckName, shape, setShowLiners, setShowDecks, setShape, setShowInfo }) {

  return (
    <>
      <div className='controls-bar h-100 d-grid gap-3'>
        <Button variant="primary" className='text-start' disabled={!deckName} onClick={() => setShowLiners()}>
          <LinerIcon /> {liner.liner_name ? <> <span className='pool-deck ms-2'>CHANGE LINER</span></> : ''}
        </Button>
        <Button variant="primary" className='text-start' disabled={!deckName} onClick={() => setShowDecks()}>
          <DeckIcon /> {deckName ? <> <span className='pool-deck ms-2'>CHANGE DECK</span></> : ''}
        </Button>
        <div className='control-switch d-flex align-items-center justify-content-center'>
          <label title="Freeform" htmlFor='shape-switch' className='form-check-label pe-1'>Freeform</label>
          <Form.Check
            inline
            type="switch"
            id="shape-switch"
            checked={shape === '4'}
            disabled={!deckName}
            onChange={() => setShape()}
          />
          <label title="Rectangle" htmlFor='shape-switch' className='form-check-label ps-1'>Rectangle</label>
        </div>
      </div>
    </>
  );
}
