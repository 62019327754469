import React from 'react';

class LinerImage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { loaded: false }
  }
  render() {
    return (
      <div className='radio-list-img'>
        {this.state.loaded ? null :
          <div className='wrapper'>
            <div className='spinner-border text-secondary d-flex justify-content-center align-items-center' role="status">
              <span className='visually-hidden'>Loading...</span>
            </div>
          </div>
        }
        <img
          className='mb-2'
          style={{ display: this.state.loaded ? 'block' : 'none' }}
          alt={this.props.liner_name}
          src={this.props.dataUrl + this.props.image}
          onLoad={() => this.setState({ loaded: true })}
        />
      </div>
    );
  }
}

export default LinerImage