import React from 'react';
import { useEffect, useState } from 'react';
import { ReactComponent as SearchIcon } from '../images/icons/search.svg';

export default function Filters({ url, setFilterCollection, setFilterCategory, setFilterWater, setFilterText, filters }) {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  // Note: the empty deps array [] means
  // this useEffect will run once
  // similar to componentDidMount()
  // passing url runs everytime url changes
  useEffect(() => {
    fetch(url)
      .then(res => res.json())
      .then(
        (result) => {
          setIsLoaded(true);
          setItems(result);
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      )
  }, [url])
  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    return <div>Loading...</div>;
  } else {

    const collections = new Set();
    const categories = new Set();
    const waters = new Set();

    const resultCollections = items.filter((o) => {
      if (collections.has(o.collection_id)) return false;
      collections.add(o.collection_id);
      return true;
    });
    const resultCategories = items.filter((o) => {
      if (categories.has(o.category_id)) return false;
      categories.add(o.category_id);
      return true;
    });
    const resultWaters = items.filter((o) => {
      if (waters.has(o.water_id)) return false;
      waters.add(o.water_id);
      return true;
    });

    return (
      <div className='btn-toolbar' role="toolbar" aria-label="Toolbar with filters">
        <div className='input-group'>
          <div className='input-group-text my-2 ms-1' id='btnGroup'><SearchIcon /></div>
          <input type="text" className='form-control my-2 me-1' placeholder="Filter by product name" aria-label="Search" aria-describedby="btnGroup" onChange={(e) => setFilterText(e.target.value)} />
        </div>
        {(filters.collection_id === 'all') && (
          <div className="btn-group my-2 mx-1" role="group">
            <button type="button" className="btn btn-primary dropdown-toggle" data-bs-toggle="dropdown"
              aria-expanded="false">
              Collection
            </button>
            <ul className="dropdown-menu" aria-labelledby="btnGroupCollection">
              {resultCollections.map((item) => (
                <li key={item.collection_id}>
                  <button
                    className={`dropdown-item ${item.collection_id === filters.collection_id ? "active" : ""}`}
                    data-value={item.collection_id}
                    onClick={(e) => setFilterCollection(e.target.dataset.value)}
                  >
                    {item.collection_name}
                  </button>
                </li>
              ))}
            </ul>
          </div>
        )}
        {(filters.collection_id !== 'all') && (
          <>
            {resultCollections
              .filter(function (item) {
                return (filters.collection_id === item.collection_id);
              })
              .map((item) => (
                <button
                  type="button"
                  className={`btn btn-secondary position-relative my-2 mx-1 ${item.collection_id === filters.collection_id ? "active" : ""}`}
                  key={item.collection_id}
                  data-value={item.collection_id}
                  onClick={(e) => setFilterCollection(e.target.dataset.value)}
                >
                  {item.collection_name}
                </button>
              ))}
          </>
        )}
        {(filters.category_id === 'all') && (
          <div className="btn-group my-2 mx-1" role="group">
            <button id="btnGroupCategory" type="button" className="btn btn-primary dropdown-toggle" data-bs-toggle="dropdown"
              aria-expanded="false">
              Category
            </button>
            <ul className="dropdown-menu dropdown-menu-dark" aria-labelledby="btnGroupCategory">
              {resultCategories.map((item) => (
                <li key={item.category_id}>
                  <button
                    className={`dropdown-item ${item.category_id === filters.category_id ? "active" : ""}`}
                    data-value={item.category_id}
                    onClick={(e) => setFilterCategory(e.target.dataset.value)}
                  >
                    {item.category_name.startsWith('aqua') ? <img src={'images/categories/' + item.category_name + '-w.png'} alt={item.category_name} data-value={item.category_id}
                      onClick={(e) => setFilterCategory(e.target.dataset.value)} /> : item.category_name}
                  </button>
                </li>
              ))}
            </ul>
          </div>
        )}
        {(filters.category_id !== 'all') && (
          <>
            {resultCategories
              .filter(function (item) {
                return (filters.category_id === item.category_id);
              })
              .map((item) => (
                <button
                  type="button"
                  className={`btn btn-secondary position-relative my-2 mx-1 ${item.category_id === filters.category_id ? "active" : ""}`}
                  key={item.category_id}
                  data-value={item.category_id}
                  onClick={(e) => setFilterCategory(e.target.dataset.value)}
                >
                  {item.category_name.startsWith('aqua') ? <img src={'images/categories/' + item.category_name + '-w.png'} alt={item.category_name} data-value={item.category_id}
                    onClick={(e) => setFilterCategory(e.target.dataset.value)} /> : item.category_name}
                </button>
              ))}
          </>
        )}
        {(filters.water_id === 'all') && (
          <div className="btn-group my-2 mx-1" role="group">
            <button id="btnGroupWater" type="button" className="btn btn-primary dropdown-toggle" data-bs-toggle="dropdown"
              aria-expanded="false">
              Water Colour
            </button>
            <ul className="dropdown-menu p-0" aria-labelledby="btnGroupWater">
              {resultWaters.map((item) => (
                <li key={item.water_id}>
                  <button
                    className={`dropdown-item position-relative ${item.water_id === filters.water_id ? "active" : ""}`}
                    data-value={item.water_id}
                    onClick={(e) => setFilterWater(e.target.dataset.value)}
                  >
                    <img src={'images/water/' + item.water_name.split(' ').join('-').toLowerCase() + '.png'} alt={item.water_name} data-value={item.water_id} onClick={(e) => setFilterWater(e.target.dataset.value)} className='pe-2' />
                    {item.water_name}
                  </button>
                </li>
              ))}
            </ul>
          </div>
        )}
        {(filters.water_id !== 'all') && (
          <>
            {resultWaters
              .filter(function (item) {
                return (filters.water_id === item.water_id);
              })
              .map((item) => (
                <button
                  type="button"
                  className={`btn btn-secondary position-relative my-2 mx-1 ${item.water_id === filters.water_id ? "active" : ""}`}
                  key={item.water_id}
                  data-value={item.water_id}
                  onClick={(e) => setFilterWater(e.target.dataset.value)}
                >
                  <img src={'images/water/' + item.water_name.split(' ').join('-').toLowerCase() + '.png'} alt={item.water_name} data-value={item.water_id} onClick={(e) => setFilterWater(e.target.dataset.value)} className='pe-2' />
                  {item.water_name}
                </button>
              ))}
          </>
        )}
      </div>
    );
  }
}
