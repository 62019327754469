import React from 'react';
import LinerImage from './LinerImage';

export default function Liners(props) {
  const liners = props.linersData
    .filter(item => {
      if (props.filters.text === '') return true
      if (item.liner_name.toLowerCase().includes(props.filters.text)) {
        return true
      }
    })
    .filter(item => {
      return (props.filters.collection_id === 'all' || props.filters.collection_id === item.collection_id);
    })
    .filter(function (item) {
      return (props.filters.category_id === 'all' || props.filters.category_id === item.category1_id || props.filters.category_id === item.category2_id);
    })
    .filter(function (item) {
      return (props.filters.water_id === 'all' || props.filters.water_id === item.water_id);
    })
    .map((item) => {
      return (
        <div key={item.liner_id} className='col mx-3'>
          <label>
            <input
              type="radio"
              value={item.liner_id}
              checked={props.liner.liner_id === item.liner_id}
              onChange={() => props.onClick({
                liner_id: item.liner_id,
                liner_name: item.liner_name,
                liner_desc: item.desc,
                liner_base: item.base_film,
                liner_cat1: item.category1_name,
                liner_cat2: item.category2_name,
                liner_ico1: item.icon1,
                liner_ico2: item.icon2,
                liner_col: item.collection_id,
              })}
              name="liners"
              disabled={props.disabled}
            />
            <LinerImage image={item.image} name={item.liner_name} dataUrl={props.dataUrl} />
            {item.liner_name}
          </label>
        </div>
      )
    })

  return <div className='row liners radio-list'>{liners}</div>

}
