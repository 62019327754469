import React, { useState } from 'react';
import { PDFDocument, StandardFonts, rgb, PDFString, PDFArray, PDFName } from 'pdf-lib';
import { ReactComponent as SaveIcon } from '../images/ui/save.svg';
import Button from 'react-bootstrap/Button';
import { useAsyncFn } from 'react-use';
import FabricatorsData from './FabricatorsData';

const PdfNa = ({ shape, liner_info, deck, siteUrl, spacing, headingSize, topSpacing, textSize, textSpacing, first, second, third, initialY, linkSpace }) => {
  const [loadStatus, setLoadStatus] = useState(false);
  const [state, doFetch] = useAsyncFn(async () => {
    const existingPdfBytes = await fetch(
      siteUrl + '/pdf/na.pdf'
    ).then((res) => res.arrayBuffer());
    const linerImgUrl = siteUrl + '/pdf/images/liners/' + liner_info.liner_id + '/liner.jpg';
    const deckImgUrl = siteUrl + '/pdf/images/deck/' + deck.deck_id + '.jpg';
    const mainImgUrl = siteUrl + '/pdf/images/liners/' + liner_info.liner_id + '/' + shape + '_' + deck.deck_id + '.jpg';

    const linerImgBytes = await fetch(linerImgUrl).then((res) => res.arrayBuffer());
    const deckImgBytes = await fetch(deckImgUrl).then((res) => res.arrayBuffer());
    const mainImgBytes = await fetch(mainImgUrl).then((res) => res.arrayBuffer());

    const pdfDoc = await PDFDocument.load(existingPdfBytes);
    const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);
    const helveticaFontBold = await pdfDoc.embedFont(StandardFonts.HelveticaBold);

    const pages = pdfDoc.getPages();
    const firstPage = pages[0];
    const secondPage = pages[1];

    const linerImg = await pdfDoc.embedJpg(linerImgBytes);
    const deckImg = await pdfDoc.embedJpg(deckImgBytes);
    const mainImg = await pdfDoc.embedJpg(mainImgBytes);

    const mainDims = mainImg.scale(0.205);

    const svgPath1 = 'm-54.398 19.355v97.367h182.03v-97.367h-182.03zm4.9366 1.0413h172.16c2.1987 0 3.9688 1.7701 3.9688 3.9688v87.347c0 2.1987-1.7701 3.9688-3.9688 3.9688h-172.16c-2.1987 0-3.9688-1.7701-3.9688-3.9688v-87.347c0-2.1987 1.7701-3.9688 3.9688-3.9688z';

    const svgPath3 = 'm36.943 83.569v30.339h30.339v-30.339zm6.7267 0.29146h16.885c3.554 0 6.4353 2.8803 6.4353 6.4342v16.886c0 3.554-2.8813 6.4353-6.4353 6.4353h-16.885c-3.554 0-6.4353-2.8813-6.4353-6.4353v-16.886c0-3.554 2.8813-6.4342 6.4353-6.4342z';

    const svgPath2 = 'm0 0h487.66c6.931 0 12.55-5.619 12.55-12.55v-247.24c0-6.932-5.619-12.55-12.55-12.55h-487.66c-6.931 0-12.55 5.618-12.55 12.55v247.24c0 6.931 5.619 12.55 12.55 12.55m0-5.379c-3.954 0-7.172-3.217-7.172-7.171v-247.24c0-3.955 3.218-7.172 7.172-7.172h487.66c3.954 0 7.171 3.217 7.171 7.172v247.24c0 3.954-3.217 7.171-7.171 7.171h-487.66';

    const fabricators = FabricatorsData.filter(function (item) {
      return (liner_info.liner_id === item.tid);
    });

    firstPage.drawText(liner_info.liner_name, {
      x: 182,
      y: 360,
      size: 12,
      font: helveticaFont,
      color: rgb(0.03, 0.14, 0.29),
    });

    firstPage.drawText(deck.deck_name, {
      x: 423,
      y: 360,
      size: 12,
      font: helveticaFont,
      color: rgb(0.03, 0.14, 0.29),
    });

    firstPage.drawText(liner_info.liner_base, {
      x: 230.5,
      y: 339.5,
      size: 9,
      font: helveticaFont,
      color: rgb(0.03, 0.14, 0.29),
    });

    firstPage.drawText(liner_info.liner_desc.replace(/<[^>]+>/g, ''), {
      x: 85,
      y: 280,
      size: 10,
      lineHeight: 13,
      font: helveticaFont,
      color: rgb(0.03, 0.14, 0.29),
      maxWidth: 450
    });

    firstPage.drawImage(mainImg, {
      x: firstPage.getWidth() / 2 - mainDims.width / 2 - 18,
      y: firstPage.getHeight() - mainDims.height - 85,
      width: mainDims.width + 32,
      height: mainDims.height,
    })

    firstPage.drawSvgPath(svgPath1, {
      x: firstPage.getWidth() / 2 - mainDims.width / 4 + 12,
      y: firstPage.getHeight() - 28,
      color: rgb(1, 1, 1),
      scale: 2.84,
    })

    firstPage.drawSvgPath(svgPath2, {
      x: firstPage.getWidth() / 2 - mainDims.width / 2 - 10.5,
      y: firstPage.getHeight() - mainDims.height - 90,
      color: rgb(0.02, 0.18, 0.37),
    })

    firstPage.drawImage(linerImg, {
      x: 82,
      y: 334,
      width: 85,
      height: 85,
    });

    firstPage.drawImage(deckImg, {
      x: 323,
      y: 334,
      width: 85,
      height: 85,
    });

    firstPage.drawSvgPath(svgPath3, {
      x: -23,
      y: 656,
      color: rgb(1, 1, 1),
      scale: 2.83,
    })

    firstPage.drawSvgPath(svgPath3, {
      x: 218,
      y: 656,
      color: rgb(1, 1, 1),
      scale: 2.83,
    })

    if (liner_info.liner_cat1.startsWith('aqua')) {
      const linerCat1ImgUrl = siteUrl + '/pdf/images/aquafeature/' + liner_info.liner_cat1 + '.png';
      const linerCat1ImgBytes = await fetch(linerCat1ImgUrl).then((res) => res.arrayBuffer());
      const linerCat1Img = await pdfDoc.embedPng(linerCat1ImgBytes);

      firstPage.drawImage(linerCat1Img, {
        x: 145,
        y: 400,
        width: 30,
        height: 30,
      });
    }

    if (liner_info.liner_cat2.startsWith('aqua')) {
      const linerCat2ImgUrl = siteUrl + '/pdf/images/aquafeature/' + liner_info.liner_cat2 + '.png';
      const linerCat2ImgBytes = await fetch(linerCat2ImgUrl).then((res) => res.arrayBuffer());
      const linerCat2Img = await pdfDoc.embedPng(linerCat2ImgBytes);

      firstPage.drawImage(linerCat2Img, {
        x: 120,
        y: 405,
        width: 30,
        height: 30,
      });
    }

    const createPageLinkAnnotation = (page, uri, rect) =>
      page.doc.context.register(
        page.doc.context.obj({
          Type: 'Annot',
          Subtype: 'Link',
          Rect: rect,
          C: [0, 0, 1],
          A: {
            Type: 'Action',
            S: 'URI',
            URI: PDFString.of(uri),
          },
        }),
      );

    let annots;
    try {
      annots = secondPage.node.lookup(PDFName.of('Annots'), PDFArray);

    } catch (error) {
      console.log('no links found:', error)
      annots = pdfDoc.context.obj([]);
      secondPage.node.set(PDFName.of("Annots"), annots);
    }

    // First row
    if (fabricators[0]) {
      secondPage.drawText(fabricators[0].name, {
        x: first,
        y: initialY - topSpacing,
        size: headingSize,
        font: helveticaFontBold,
        color: rgb(0.16, 0.75, 0.82),
      })
      secondPage.drawText(fabricators[0].address, {
        x: first,
        y: initialY - textSpacing - topSpacing,
        size: textSize,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      })
      secondPage.drawText('Website:', {
        x: first,
        y: initialY - (textSpacing * 2) - topSpacing,
        size: textSize,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      })
      secondPage.drawText(fabricators[0].website, {
        x: first + 36,
        y: initialY - (textSpacing * 2) - topSpacing,
        size: textSize,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      })
      const link = createPageLinkAnnotation(secondPage, fabricators[0].url, [first - 5, initialY, first + 150, initialY - 40]);
      annots.push(link)
    }
    if (fabricators[1]) {
      secondPage.drawText(fabricators[1].name, {
        x: second,
        y: initialY - topSpacing,
        size: headingSize,
        font: helveticaFontBold,
        color: rgb(0.16, 0.75, 0.82),
      })
      secondPage.drawText(fabricators[1].address, {
        x: second,
        y: initialY - textSpacing - topSpacing,
        size: textSize,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      })
      secondPage.drawText('Website:', {
        x: second,
        y: initialY - (textSpacing * 2) - topSpacing,
        size: textSize,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      })
      secondPage.drawText(fabricators[1].website, {
        x: second + 36,
        y: initialY - (textSpacing * 2) - topSpacing,
        size: textSize,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      })
      const link = createPageLinkAnnotation(secondPage, fabricators[1].url, [second - 5, initialY, second + 150, initialY - 40]);
      annots.push(link)
    }
    if (fabricators[2]) {
      secondPage.drawText(fabricators[2].name, {
        x: third,
        y: initialY - topSpacing,
        size: headingSize,
        font: helveticaFontBold,
        color: rgb(0.16, 0.75, 0.82),
      })
      secondPage.drawText(fabricators[2].address, {
        x: third,
        y: initialY - textSpacing - topSpacing,
        size: textSize,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      })
      secondPage.drawText('Website:', {
        x: third,
        y: initialY - (textSpacing * 2) - topSpacing,
        size: textSize,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      })
      secondPage.drawText(fabricators[2].website, {
        x: third + 36,
        y: initialY - (textSpacing * 2) - topSpacing,
        size: textSize,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      })
      const link = createPageLinkAnnotation(secondPage, fabricators[2].url, [third - 5, initialY, third + 150, initialY - 40]);
      annots.push(link)
    }

    // Second row
    if (fabricators[3]) {
      secondPage.drawText(fabricators[3].name, {
        x: first,
        y: initialY - spacing - topSpacing,
        size: headingSize,
        font: helveticaFontBold,
        color: rgb(0.16, 0.75, 0.82),
      })
      secondPage.drawText(fabricators[3].address, {
        x: first,
        y: initialY - textSpacing - spacing - topSpacing,
        size: textSize,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      })
      secondPage.drawText('Website:', {
        x: first,
        y: initialY - (textSpacing * 2) - spacing - topSpacing,
        size: textSize,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      })
      secondPage.drawText(fabricators[3].website, {
        x: first + 36,
        y: initialY - (textSpacing * 2) - spacing - topSpacing,
        size: textSize,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      })
      const link = createPageLinkAnnotation(secondPage, fabricators[3].url, [first - 5, initialY - linkSpace, first + 150, initialY - linkSpace - 40]);
      annots.push(link)
    }
    if (fabricators[4]) {
      secondPage.drawText(fabricators[4].name, {
        x: second,
        y: initialY - spacing - topSpacing,
        size: headingSize,
        font: helveticaFontBold,
        color: rgb(0.16, 0.75, 0.82),
      })
      secondPage.drawText(fabricators[4].address, {
        x: second,
        y: initialY - textSpacing - spacing - topSpacing,
        size: textSize,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      })
      secondPage.drawText('Website:', {
        x: second,
        y: initialY - (textSpacing * 2) - spacing - topSpacing,
        size: textSize,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      })
      secondPage.drawText(fabricators[4].website, {
        x: second + 36,
        y: initialY - (textSpacing * 2) - spacing - topSpacing,
        size: textSize,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      })
      const link = createPageLinkAnnotation(secondPage, fabricators[4].url, [second - 5, initialY - linkSpace, second + 150, initialY - linkSpace - 40]);
      annots.push(link)
    }
    if (fabricators[5]) {
      secondPage.drawText(fabricators[5].name, {
        x: third,
        y: initialY - spacing - topSpacing,
        size: headingSize,
        font: helveticaFontBold,
        color: rgb(0.16, 0.75, 0.82),
      })
      secondPage.drawText(fabricators[5].address, {
        x: third,
        y: initialY - textSpacing - spacing - topSpacing,
        size: textSize,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      })
      secondPage.drawText('Website:', {
        x: third,
        y: initialY - (textSpacing * 2) - spacing - topSpacing,
        size: textSize,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      })
      secondPage.drawText(fabricators[5].website, {
        x: third + 36,
        y: initialY - (textSpacing * 2) - spacing - topSpacing,
        size: textSize,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      })
      const link = createPageLinkAnnotation(secondPage, fabricators[5].url, [third - 5, initialY - linkSpace, third + 150, initialY - linkSpace - 40]);
      annots.push(link)
    }

    // Third row
    if (fabricators[6]) {
      secondPage.drawText(fabricators[6].name, {
        x: first,
        y: initialY - topSpacing - spacing * 2,
        size: headingSize,
        font: helveticaFontBold,
        color: rgb(0.16, 0.75, 0.82),
      })
      secondPage.drawText(fabricators[6].address, {
        x: first,
        y: initialY - textSpacing - topSpacing - spacing * 2,
        size: textSize,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      })
      secondPage.drawText('Website:', {
        x: first,
        y: initialY - (textSpacing * 2) - topSpacing - spacing * 2,
        size: textSize,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      })
      secondPage.drawText(fabricators[6].website, {
        x: first + 36,
        y: initialY - (textSpacing * 2) - topSpacing - spacing * 2,
        size: textSize,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      })
      const link = createPageLinkAnnotation(secondPage, fabricators[6].url, [first - 5, initialY - (linkSpace * 2), first + 150, initialY - (linkSpace * 2) - 40]);
      annots.push(link)
    }
    if (fabricators[7]) {
      secondPage.drawText(fabricators[7].name, {
        x: second,
        y: initialY - topSpacing - spacing * 2,
        size: headingSize,
        font: helveticaFontBold,
        color: rgb(0.16, 0.75, 0.82),
      })
      secondPage.drawText(fabricators[7].address, {
        x: second,
        y: initialY - textSpacing - topSpacing - spacing * 2,
        size: textSize,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      })
      secondPage.drawText('Website:', {
        x: second,
        y: initialY - (textSpacing * 2) - topSpacing - spacing * 2,
        size: textSize,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      })
      secondPage.drawText(fabricators[7].website, {
        x: second + 36,
        y: initialY - (textSpacing * 2) - topSpacing - spacing * 2,
        size: textSize,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      })
      const link = createPageLinkAnnotation(secondPage, fabricators[7].url, [second - 5, initialY - (linkSpace * 2), second + 150, initialY - (linkSpace * 2) - 40]);
      annots.push(link)
    }
    if (fabricators[8]) {
      secondPage.drawText(fabricators[8].name, {
        x: third,
        y: initialY - topSpacing - spacing * 2,
        size: headingSize,
        font: helveticaFontBold,
        color: rgb(0.16, 0.75, 0.82),
      })
      secondPage.drawText(fabricators[8].address, {
        x: third,
        y: initialY - textSpacing - topSpacing - spacing * 2,
        size: textSize,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      })
      secondPage.drawText('Website:', {
        x: third,
        y: initialY - (textSpacing * 2) - topSpacing - spacing * 2,
        size: textSize,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      })
      secondPage.drawText(fabricators[8].website, {
        x: third + 36,
        y: initialY - (textSpacing * 2) - topSpacing - spacing * 2,
        size: textSize,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      })
      const link = createPageLinkAnnotation(secondPage, fabricators[8].url, [third - 5, initialY - (linkSpace * 2), third + 150, initialY - (linkSpace * 2) - 40]);
      annots.push(link)
    }

    // Fourth row
    if (fabricators[9]) {
      secondPage.drawText(fabricators[9].name, {
        x: first,
        y: initialY - topSpacing - spacing * 3,
        size: headingSize,
        font: helveticaFontBold,
        color: rgb(0.16, 0.75, 0.82),
      })
      secondPage.drawText(fabricators[9].address, {
        x: first,
        y: initialY - textSpacing - topSpacing - spacing * 3,
        size: textSize,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      })
      secondPage.drawText('Website:', {
        x: first,
        y: initialY - (textSpacing * 2) - topSpacing - spacing * 3,
        size: textSize,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      })
      secondPage.drawText(fabricators[9].website, {
        x: first + 36,
        y: initialY - (textSpacing * 2) - topSpacing - spacing * 3,
        size: textSize,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      })
      const link = createPageLinkAnnotation(secondPage, fabricators[9].url, [first - 5, initialY - (linkSpace * 3), first + 150, initialY - (linkSpace * 3) - 40]);
      annots.push(link)
    }
    if (fabricators[10]) {
      secondPage.drawText(fabricators[10].name, {
        x: second,
        y: initialY - topSpacing - spacing * 3,
        size: headingSize,
        font: helveticaFontBold,
        color: rgb(0.16, 0.75, 0.82),
      })
      secondPage.drawText(fabricators[10].address, {
        x: second,
        y: initialY - textSpacing - topSpacing - spacing * 3,
        size: textSize,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      })
      secondPage.drawText('Website:', {
        x: second,
        y: initialY - (textSpacing * 2) - topSpacing - spacing * 3,
        size: textSize,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      })
      secondPage.drawText(fabricators[10].website, {
        x: second + 36,
        y: initialY - (textSpacing * 2) - topSpacing - spacing * 3,
        size: textSize,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      })
      const link = createPageLinkAnnotation(secondPage, fabricators[10].url, [second - 5, initialY - (linkSpace * 3), second + 150, initialY - (linkSpace * 3) - 40]);
      annots.push(link)
    }
    if (fabricators[11]) {
      secondPage.drawText(fabricators[11].name, {
        x: third,
        y: initialY - topSpacing - spacing * 3,
        size: headingSize,
        font: helveticaFontBold,
        color: rgb(0.16, 0.75, 0.82),
      })
      secondPage.drawText(fabricators[11].address, {
        x: third,
        y: initialY - textSpacing - topSpacing - spacing * 3,
        size: textSize,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      })
      secondPage.drawText('Website:', {
        x: third,
        y: initialY - (textSpacing * 2) - topSpacing - spacing * 3,
        size: textSize,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      })
      secondPage.drawText(fabricators[11].website, {
        x: third + 36,
        y: initialY - (textSpacing * 2) - topSpacing - spacing * 3,
        size: textSize,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      })
      const link = createPageLinkAnnotation(secondPage, fabricators[11].url, [third - 5, initialY - (linkSpace * 3), third + 150, initialY - (linkSpace * 3) - 40]);
      annots.push(link)
    }

    // Fifth row
    if (fabricators[12]) {
      secondPage.drawText(fabricators[12].name, {
        x: first,
        y: initialY - topSpacing - spacing * 4,
        size: headingSize,
        font: helveticaFontBold,
        color: rgb(0.16, 0.75, 0.82),
      })
      secondPage.drawText(fabricators[12].address, {
        x: first,
        y: initialY - textSpacing - topSpacing - spacing * 4,
        size: textSize,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      })
      secondPage.drawText('Website:', {
        x: first,
        y: initialY - (textSpacing * 2) - topSpacing - spacing * 4,
        size: textSize,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      })
      secondPage.drawText(fabricators[12].website, {
        x: first + 36,
        y: initialY - (textSpacing * 2) - topSpacing - spacing * 4,
        size: textSize,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      })
      const link = createPageLinkAnnotation(secondPage, fabricators[12].url, [first - 5, initialY - (linkSpace * 4), first + 150, initialY - (linkSpace * 4) - 40]);
      annots.push(link)
    }
    if (fabricators[13]) {
      secondPage.drawText(fabricators[13].name, {
        x: second,
        y: initialY - topSpacing - spacing * 4,
        size: headingSize,
        font: helveticaFontBold,
        color: rgb(0.16, 0.75, 0.82),
      })
      secondPage.drawText(fabricators[13].address, {
        x: second,
        y: initialY - textSpacing - topSpacing - spacing * 4,
        size: textSize,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      })
      secondPage.drawText('Website:', {
        x: second,
        y: initialY - (textSpacing * 2) - topSpacing - spacing * 4,
        size: textSize,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      })
      secondPage.drawText(fabricators[13].website, {
        x: second + 36,
        y: initialY - (textSpacing * 2) - topSpacing - spacing * 4,
        size: textSize,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      })
      const link = createPageLinkAnnotation(secondPage, fabricators[13].url, [second - 5, initialY - (linkSpace * 4), second + 150, initialY - (linkSpace * 4) - 40]);
      annots.push(link)
    }
    if (fabricators[14]) {
      secondPage.drawText(fabricators[14].name, {
        x: third,
        y: initialY - topSpacing - spacing * 4,
        size: headingSize,
        font: helveticaFontBold,
        color: rgb(0.16, 0.75, 0.82),
      })
      secondPage.drawText(fabricators[14].address, {
        x: third,
        y: initialY - textSpacing - topSpacing - spacing * 4,
        size: textSize,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      })
      secondPage.drawText('Website:', {
        x: third,
        y: initialY - (textSpacing * 2) - topSpacing - spacing * 4,
        size: textSize,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      })
      secondPage.drawText(fabricators[14].website, {
        x: third + 36,
        y: initialY - (textSpacing * 2) - topSpacing - spacing * 4,
        size: textSize,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      })
      const link = createPageLinkAnnotation(secondPage, fabricators[14].url, [third - 5, initialY - (linkSpace * 4), third + 150, initialY - (linkSpace * 4) - 40]);
      annots.push(link)
    }

    // Sixth row
    if (fabricators[15]) {
      secondPage.drawText(fabricators[15].name, {
        x: first,
        y: initialY - topSpacing - spacing * 5,
        size: headingSize,
        font: helveticaFontBold,
        color: rgb(0.16, 0.75, 0.82),
      })
      secondPage.drawText(fabricators[15].address, {
        x: first,
        y: initialY - textSpacing - topSpacing - spacing * 5,
        size: textSize,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      })
      secondPage.drawText('Website:', {
        x: first,
        y: initialY - (textSpacing * 2) - topSpacing - spacing * 5,
        size: textSize,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      })
      secondPage.drawText(fabricators[15].website, {
        x: first + 36,
        y: initialY - (textSpacing * 2) - topSpacing - spacing * 5,
        size: textSize,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      })
      const link = createPageLinkAnnotation(secondPage, fabricators[15].url, [first - 5, initialY - (linkSpace * 5), first + 150, initialY - (linkSpace * 5) - 40]);
      annots.push(link)
    }
    if (fabricators[16]) {
      secondPage.drawText(fabricators[16].name, {
        x: second,
        y: initialY - topSpacing - spacing * 5,
        size: headingSize,
        font: helveticaFontBold,
        color: rgb(0.16, 0.75, 0.82),
      })
      secondPage.drawText(fabricators[16].address, {
        x: second,
        y: initialY - textSpacing - topSpacing - spacing * 5,
        size: textSize,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      })
      secondPage.drawText('Website:', {
        x: second,
        y: initialY - (textSpacing * 2) - topSpacing - spacing * 5,
        size: textSize,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      })
      secondPage.drawText(fabricators[16].website, {
        x: second + 36,
        y: initialY - (textSpacing * 2) - topSpacing - spacing * 5,
        size: textSize,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      })
      const link = createPageLinkAnnotation(secondPage, fabricators[16].url, [second - 5, initialY - (linkSpace * 5), second + 150, initialY - (linkSpace * 5) - 40]);
      annots.push(link)
    }
    if (fabricators[17]) {
      secondPage.drawText(fabricators[17].name, {
        x: third,
        y: initialY - topSpacing - spacing * 5,
        size: headingSize,
        font: helveticaFontBold,
        color: rgb(0.16, 0.75, 0.82),
      })
      secondPage.drawText(fabricators[17].address, {
        x: third,
        y: initialY - textSpacing - topSpacing - spacing * 5,
        size: textSize,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      })
      secondPage.drawText('Website:', {
        x: third,
        y: initialY - (textSpacing * 2) - topSpacing - spacing * 5,
        size: textSize,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      })
      secondPage.drawText(fabricators[17].website, {
        x: third + 36,
        y: initialY - (textSpacing * 2) - topSpacing - spacing * 5,
        size: textSize,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      })
      const link = createPageLinkAnnotation(secondPage, fabricators[17].url, [third - 5, initialY - (linkSpace * 5), third + 150, initialY - (linkSpace * 5) - 40]);
      annots.push(link)
    }

    // Seventh row
    if (fabricators[18]) {
      secondPage.drawText(fabricators[18].name, {
        x: first,
        y: initialY - topSpacing - spacing * 6,
        size: headingSize,
        font: helveticaFontBold,
        color: rgb(0.16, 0.75, 0.82),
      })
      secondPage.drawText(fabricators[18].address, {
        x: first,
        y: initialY - textSpacing - topSpacing - spacing * 6,
        size: textSize,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      })
      secondPage.drawText('Website:', {
        x: first,
        y: initialY - (textSpacing * 2) - topSpacing - spacing * 6,
        size: textSize,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      })
      secondPage.drawText(fabricators[18].website, {
        x: first + 36,
        y: initialY - (textSpacing * 2) - topSpacing - spacing * 6,
        size: textSize,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      })
      const link = createPageLinkAnnotation(secondPage, fabricators[18].url, [first - 5, initialY - (linkSpace * 6), first + 150, initialY - (linkSpace * 6) - 40]);
      annots.push(link)
    }

    const pdfBytes = await pdfDoc.save();
    const docUrl = URL.createObjectURL(
      new Blob([pdfBytes], { type: 'application/pdf' })
    );

    setLoadStatus(true);

    return docUrl
  }, [liner_info.liner_id, deck.deck_id, shape]);

  return (
    <div>
      {state.loading
        ? <div><Button variant="light" className='text-start ms-4 mt-4'><SaveIcon className='mb-1' /> <span className='ms-1'>Generating...</span></Button></div>
        : state.error
          ? <div>Error: {state.error.message}</div>
          : loadStatus ? <div><Button variant="light" className='text-start ms-4 mt-4' href={state.value} download={`${liner_info.liner_name}.pdf`}><SaveIcon className='mb-1' /> <span className='ms-1'>Download PDF</span></Button></div>
            : <div><Button variant="light" className='text-start ms-4 mt-4' onClick={() => doFetch()}><SaveIcon className='mb-1' /> <span className='ms-1'>Save As PDF</span></Button></div>
      }
    </div>
  );
};

export default PdfNa;
