import React from 'react';
import { useState } from 'react';
import { ReactComponent as Logo } from '../images/ui/logo.svg';
import Instructions from './Instructions';
import Social from './Social';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { ReactComponent as Contact } from '../images/ui/contact.svg';

export default function InstructionsModal({ handleShow, showInstructions, onClick }) {
  const [show, setShow] = useState(true);

  const handleClose = () => setShow(false);

  return (
    <>
      <Modal
        show={showInstructions && show}
        onExit={() => handleShow()}
        onHide={() => setShow(false)}
        dialogClassName="modal-instructions m-0"
        contentClassName="gradient1 border-0"
        aria-labelledby="instructions-title"
        centered={false}
      >
        <Modal.Header closeButton closeVariant="white" className="border-0">
          <div className='d-flex align-items-center'><div className='logo'><button onClick={onClick}><Logo className='logo' /></button></div><h1 className='fs-4 ms-3 mb-0'><a className='text-white text-decoration-none' href='https://poolsidebycgt.com/'>Poolside Visualizer by CGT</a></h1></div>
        </Modal.Header >
        <Modal.Body>
          <Instructions />
          <div className='row justify-content-center mt-4 mb-2 g-1'><Social /></div>
          <div className='mt-3 text-center'><a className='text-white text-decoration-none' href='https://poolsidebycgt.com/about/#contact'><Contact alt='Contact Us' /> Contact Us</a></div>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
